import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-20a35649"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  style: {
    "margin-top": "1.1rem",
    "z-index": "-1",
    "height": "auto"
  }
};
var _hoisted_2 = ["height", "src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_common_footer1 = _resolveComponent("common-footer1");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("iframe", {
    id: "iframe",
    ref: "iframes",
    height: $data.iframeHeight,
    scrolling: "no",
    width: "100%",
    src: _ctx.urls,
    onLoad: _cache[0] || (_cache[0] = function () {
      return $options.adjustIframe && $options.adjustIframe.apply($options, arguments);
    })
  }, null, 40, _hoisted_2)]), _createElementVNode("div", {
    style: _normalizeStyle({
      margin: '1rem 0 .4rem',
      height: '.02rem',
      background: '#E5E6EB'
    })
  }, null, 4), _createVNode(_component_common_footer1)], 64);
}